import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { BIG_ZERO, NULL_ADDRESS } from '../utils/formatbalance';
import { log } from '../utils/logger';
import {
    getBaseNewZunUsdAddress,
    getBaseOldZunUsdAddress,
    getChainClient,
    getCrvUsdAddress,
    getFrxEthAddress,
    getUpdateInterval,
    getWethAddress,
    getZunEthAddress,
    getZunEthApsAddress,
    getZunUsdAddress,
    getZunUsdApsAddress,
} from '../utils/zunami';
import { getDaiAddress, getUsdcAddress, getUsdtAddress } from '../sushi/lib/constants';
import { Address } from 'viem';
import { erc20ABI } from 'wagmi';
import { base } from '../components/NetworkSelector/NetworkSelector';

function getEthBalance(account: Address, chainId: number = 1) {
    return getChainClient(chainId).getBalance({ address: account });
}

function getCoinBalance(coinAddress: Address, account: Address, chainId: number = 1) {
    return getChainClient(chainId).readContract({
        address: coinAddress,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [account],
    });
}

export const coins = [
    'DAI', // 0
    'USDC', // 1
    'USDT', // 2
    'FRAX', // 3
    'zunUSD', // 4
    'zunETH', // 5
    'apsZunUSDLP', // 6
    'apsZunETHLP', // 7
    'ZUN', // 8
    'WETH', // 9
    'FRXETH', // 10
    'CRVUSD', // 11
    'ETH', // 12,
    'zunUSD_base_new', // 13 [BASE] zunUsd (new)
    'zunUSD_base_old' // 14 [BASE] zunUsd (old!)
];

export function getCoinAddressByIndex(index: number, chainId: number): Address {
    let result: Address = NULL_ADDRESS;

    switch (index) {
        case 0: // DAI
            result = getDaiAddress(chainId);
            break;
        case 1: // USDC
            result = getUsdcAddress(chainId);
            break;
        case 2: // USDT
            result = getUsdtAddress(chainId);
            break;
        case 3: // FRAX
            result = NULL_ADDRESS;
            break;
        case 4: // ZunUSD
            result = getZunUsdAddress(chainId);
            break;
        case 5: // ZunETH
            result = getZunEthAddress(chainId);
            break;
        case 6: // apsZunUSDLP
            result = getZunUsdApsAddress(chainId);
            break;
        case 7: // apsZunETHLP
            result = getZunEthApsAddress(chainId);
            break;
        case 8: // ZUN
            result = NULL_ADDRESS;
            break;
        case 9: // WETH
            result = getWethAddress();
            break;
        case 10: // FRXETH
            result = getFrxEthAddress();
            break;
        case 11: // CRVUSD
            result = getCrvUsdAddress();
            break;
        case 13: // zunUSD (new)
            result = getBaseNewZunUsdAddress();
            break;
        case 14: // zunUSD (old)
            result = getBaseOldZunUsdAddress();
            break;
    }

    return result;
}

export const useUserBalances = (account: Address = NULL_ADDRESS, chainId: number | undefined) => {
    const [balance, setBalance] = useState([
        BIG_ZERO, // DAI
        BIG_ZERO, // USDC
        BIG_ZERO, // USDT
        BIG_ZERO, // FRAX
        BIG_ZERO, // zunUSD
        BIG_ZERO, // zunETH
        BIG_ZERO, // apsZunUSDLP,
        BIG_ZERO, // apsZunETHLP
        BIG_ZERO, // ZUN
        BIG_ZERO, // WETH
        BIG_ZERO, // FRXETH
        BIG_ZERO, // CRVUSD
        BIG_ZERO, // ETH
        BIG_ZERO, // [BASE] zunUSD (new)
        BIG_ZERO, // [BASE] zunUSD (old)
    ]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchbalanceStables = async () => {
            if (account === NULL_ADDRESS || !chainId) {
                return;
            }

            let result = [
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                BIG_ZERO,
                // Base network
                BIG_ZERO,
                BIG_ZERO,
            ];

            setIsLoading(true);

            if (chainId === 1) {
                result = [
                    await getCoinBalance(getDaiAddress(chainId), account, chainId),
                    await getCoinBalance(getUsdcAddress(chainId), account, chainId),
                    await getCoinBalance(getUsdtAddress(chainId), account, chainId),
                    BigInt('0'),
                    await getCoinBalance(getZunUsdAddress(chainId), account, chainId), // zunUSD
                    await getCoinBalance(getZunEthAddress(chainId), account, chainId), // zunETH
                    await getCoinBalance(getZunUsdApsAddress(chainId), account, chainId), // apsZunUSDLP,
                    await getCoinBalance(getZunEthApsAddress(chainId), account, chainId), // apsZunETHLP
                    BigInt('0'), // ZUN,
                    await getCoinBalance(getWethAddress(), account, chainId), // WETH
                    await getCoinBalance(getFrxEthAddress(), account, chainId), // FRXETH
                    await getCoinBalance(getCrvUsdAddress(), account, chainId), // CRVUSD
                    await getEthBalance(account), // ETH
                    BigInt('0'),
                    BigInt('0'),
                ].map((balance: BigInt) => new BigNumber(balance.toString()));
            }

            if (chainId === base.id) {
                result = [
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    await getCoinBalance(getBaseNewZunUsdAddress(), account, chainId),// base zunUSD (new)
                    await getCoinBalance(getBaseOldZunUsdAddress(), account, chainId),// base zunUSD (old)
                ].map((balance: BigInt) => new BigNumber(balance.toString()));
            }

            // console.log('Balances:');
            // console.log(result);

            setIsLoading(false);

            setBalance(result);
        };

        if (account) {
            fetchbalanceStables();
        }

        let refreshInterval = setInterval(fetchbalanceStables, getUpdateInterval());
        return () => clearInterval(refreshInterval);
    }, [account, chainId]);

    return {
        balances: balance,
        isLoading,
    };
};
