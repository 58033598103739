import { Address, sepolia, useAccount, useNetwork, useWalletClient } from 'wagmi';
import apsAbi from '../actions/abi/sepolia/aps.json';
import { useMemo } from 'react';
import { contractAddresses } from '../sushi/lib/constants';
import BigNumber from 'bignumber.js';
import {
    BIG_TEN,
    DAI_TOKEN_DECIMAL,
    NULL_ADDRESS,
    USDT_TOKEN_DECIMAL,
    UZD_DECIMALS,
} from '../utils/formatbalance';
import { walletClient } from '../config';
import { log } from '../utils/logger';
import { base } from '../components/NetworkSelector/NetworkSelector';

const useUnstake = (
    shares: string | BigNumber,
    minTokenAmounts: Array<string> = ['0', '0', '0', '0', '0'],
    receiver: Address,
    stakingMode: string
) => {
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const { address: account } = useAccount();
    const wallet = useWalletClient();

    const contractAddress = useMemo(() => {
        if (!chainId) {
            return contractAddresses.aps[1];
        }

        if (stakingMode === 'ZETH') {
            return contractAddresses.ethAps[chainId] ?? contractAddresses.ethAps[1];
        }

        if (chainId === base.id) {
            return contractAddresses.aps[base.id];
        }

        return contractAddresses.aps[chainId] ?? contractAddresses.aps[1];
    }, [chainId, stakingMode]);

    async function onUnstake() {
        let sumToWithdraw = '0';

        if (typeof (shares) === 'string') {
            sumToWithdraw = new BigNumber(shares)
                .multipliedBy(BIG_TEN.pow(UZD_DECIMALS))
                .toString();
        } else {
            sumToWithdraw = shares.toString();
        }

        log(`${contractAddress}.withdraw(${sumToWithdraw}, [${minTokenAmounts}], '${receiver}')`);

        // @ts-ignore
        return await wallet.data.writeContract({
            address: contractAddress,
            chain: chain,
            abi: apsAbi,
            functionName: 'withdraw',
            args: [sumToWithdraw, minTokenAmounts, receiver],
            account: account || NULL_ADDRESS,
        });
    }

    return {
        withdraw: onUnstake,
    };
};

export default useUnstake;
