import { Address } from 'wagmi';
import { NULL_ADDRESS } from '../../utils/formatbalance';

// const apsAddress: Address = '0xCaB49182aAdCd843b037bBF885AD56A3162698Bd';
// const zethApsAddress: Address = '0xe47f1CD2A37c6FE69e3501AE45ECA263c5A87b2b';
// const zethAddress: Address = '0xe47f1CD2A37c6FE69e3501AE45ECA263c5A87b2b';

export const SUBTRACT_GAS_LIMIT = 100000;

export const teamMultiSig: Address = '0xb056B9A45f09b006eC7a69770A65339586231a34';

// Main Omnipool controller
export const zunamiMainnetAddress: Address = '0x618eee502CDF6b46A2199C21D1411f3F6065c940';
export const zunamiSepoliaAddress: Address = '0x4F1ed6687c6C70B9Fb717c496D1d869d78e9B094';

// APS controller
export const zunUsdApsAddress: Address = '0xd9F559280c9d308549e84946C0d668a817fcCFB5';
export const zunUsdApsSepoliaAddress: Address = '0x8cB6a65076fdA549F1B893436ca437Aa8C906894';
export const zunUsdApsBaseAddress: Address = '0x65363f1A6cb67fE045bbD2fb3c5cb81bFBEe7902';

export const zunEthApsAddress: Address = '0xD8132d8cfCA9Ed8C95e46Cb59ae6E2C9963dA61f';
export const zunEthApsSepoliaAddress: Address = '0xcC6Fc381ab661078b147591ecc8B78209634E42d';

// ZunUSD
export const zunUsdMainnetAddress: Address = '0x8C0D76C9B18779665475F3E212D9Ca1Ed6A1A0e6';
export const zunUsdSepoliaAddress: Address = '0x30bdC029Bd44F55237F79C715e669b7De209cBCE';

// ZunETH
export const zunEthMainnetAddress: Address = '0xc2e660C62F72c2ad35AcE6DB78a616215E2F2222';
export const zunEthSepoliaAddress: Address = '0x482feEd67c440e6924662aaF4aBf45992a5009eB';

// ZUN token
export const zunTokenAddress: Address = '0x6b5204b0be36771253cc38e88012e02b752f0f36';
export const zunTokenSepoliaAddress: Address = '0xbf3127C1554C02f4e60031E29f890a1A700564f6';

// ZUN Staking
export const zunStakingAddress: Address = '0x45af4F12B46682B3958B297bAcebde2cE2E795c3';
export const zunStakingSepoliaAddress: Address = '0x27A0223c1a8a9688f31d47CB89542a5CBbBa20E9';

// ZUN DAO
export const zunDaoAddress: Address = '0xEEA950a509d822CF65edcEED53d161fBaa967B3a';
export const zunDaoSepoliaAddress: Address = '0x1948Aae34416B5f3F9A94aB088D207c0195eaEEA';

// ZAP zunUSD
export const zunUsdZapAddress: Address = '0xb9fbaCc23A717D135C485ed2c08858bc2aB95154'; // ZAP v3
export const zunUsdZapSepoliaAddress: Address = '0xd611539aDAb47Afae4a75eB57D192e64729cB305';

export const zunEthZapAddress: Address = '0x2160ad71208F966948389eFC76a4Cc4930696382'; // ZAP v3
export const zunEthZapSepoliaAddress: Address = '0x4948f66D8fD4d360195258Db4e392cf9a2E7F717';

// Stablecoins mainnet
export const daiAddress: Address = '0x6B175474E89094C44Da98b954EedeAC495271d0F';
export const usdcAddress: Address = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';
export const usdtAddress: Address = '0xdac17f958d2ee523a2206206994597c13d831ec7';
export const fraxAddress: Address = '0x853d955acef822db058eb8505911ed77f175b99e';
export const wethAddress: Address = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
export const frxEthAddress: Address = '0x5e8422345238f34275888049021821e8e08caa1f';
export const crvUsdAddress: Address = '0xf939e0a03fb07f59a73314e73794be0e57ac1b4e';

// Stablecoins sepolia
export const sepDaiAddress: Address = '0x0f4bC80018eEcb8D6189E401085dfDAb978432F5';
export const sepUsdcAddress: Address = '0x979207C927081bC0aeDfc920fD60f11dcB7f2d2f';
export const sepUsdtAddress: Address = '0x6088d4E45B4490d56d2C850816F2cCE9c20D5CCe';

export const baseZunUsdZapAddress: Address = '0xD69D8808E95edD3556Ba4E3862f6e14789A2B68b';

export const getDaiAddress = (chainId: number | undefined): Address => {
    return chainId === 1 || !chainId ? daiAddress : sepDaiAddress;
};

export const getUsdcAddress = (chainId: number | undefined): Address => {
    return chainId === 1 || !chainId ? usdcAddress : sepUsdcAddress;
};

export const getUsdtAddress = (chainId: number | undefined): Address => {
    return chainId === 1 || !chainId ? usdtAddress : sepUsdtAddress;
};

export const getWethAddress = (chainId: number | undefined): Address => {
    return chainId === 1 || !chainId ? usdcAddress : sepUsdcAddress;
};

export const contractAddresses: { [index: string]: any } = {
    zunami: {
        // ETH
        1: zunamiMainnetAddress,
        // Sepolia
        11155111: zunamiSepoliaAddress,
    },
    zunUSD: {
        1: zunUsdMainnetAddress,
        11155111: zunUsdSepoliaAddress,
    },
    zunEth: {
        1: zunEthMainnetAddress,
        11155111: zunEthSepoliaAddress,
    },
    zap: {
        1: zunUsdZapAddress,
        11155111: zunUsdZapSepoliaAddress,
        8453: baseZunUsdZapAddress,
    },
    zapEth: {
        1: zunEthZapAddress,
        11155111: zunEthZapSepoliaAddress,
    },
    uzd: {
        1: '0xb40b6608B2743E691C9B54DdBDEe7bf03cd79f1c',
    },
    frax: {
        1: '0x3D8aBC464D5313a576e78706aC97F79fe1EB0b61',
    },
    aps: {
        1: zunUsdApsAddress,
        11155111: zunUsdApsSepoliaAddress,
        8453: zunUsdApsBaseAddress,
    },
    ethAps: {
        1: zunEthApsAddress,
        11155111: zunEthApsSepoliaAddress,
    },
    zun: {
        1: zunTokenAddress,
        11155111: zunTokenSepoliaAddress,
    },
    staking: {
        1: zunStakingAddress,
        11155111: zunStakingSepoliaAddress,
    },
    dao: {
        1: zunDaoAddress,
        11155111: zunDaoSepoliaAddress,
    },
    teamMultisig: {
        1: teamMultiSig,
    },
    stableZapUSD: {
        1: '0xa7EEea3E40C2E36600A607e169A7AD38eA53cf43'
    },
    stableZapETH: {
        1: '0x519A515C8D81F63EE1d09a9438FeD781131439BC'
    },
    deprecated: {},
};
